var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "publish-success-container" },
    [
      _c("Title", { attrs: { name: "发布竞买" } }),
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "message" },
          [
            _c("i", { staticClass: "icon-success el-icon-success" }),
            _c("div", { staticClass: "title" }, [_vm._v("提交成功")]),
            _c("div", { staticClass: "description" }, [
              _vm._v("您的竞买信息已提交成功，等待平台审核，请及时查询。"),
            ]),
            _c(
              "el-button",
              { attrs: { size: "small" }, on: { click: _vm.toMyPublish } },
              [_vm._v("查看我的竞买")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }