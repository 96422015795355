<template>
  <div class="publish-success-container">
    <Title name='发布竞买'></Title>
    <div class="content">
      <div class="message">
        <i class="icon-success el-icon-success"></i>
        <div class="title">提交成功</div>
        <div class="description">您的竞买信息已提交成功，等待平台审核，请及时查询。</div>
        <el-button size="small" @click="toMyPublish">查看我的竞买</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Title from '../../component/Title'
export default {
  components: {
    Title
  },
  methods: {
    toMyPublish() {
      this.$router.push({
        path: '/User/MyPublish'
      })
    }
  }
}
</script>

<style lang="less">
.publish-success-container {
  background: #fff;
    
  .content {
    display: flex;
    margin: 50px auto 0;
    width: 500px;

    .message {
      margin-left: 36px;
      text-align: center;

      .icon-success {  
        font-size: 80px;       
        color: rgb(11, 189, 11);   
      }
      .title {
        margin-bottom: 16px;
        font-size: 18px;
        font-weight: bold;
        color: #333;   
      }
      .description {
        margin-bottom: 24px;
        font-size: 14px;
        color: #333;  
      }
    }
  }
}
</style>